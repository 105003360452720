import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "core-js/es";
//import "./bootstrap";
import React, { useContext, useState } from "react";
//import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { globalConfig, globalConfigUrl } from "./configuration/app.config";
import i18n from "./i18n";
import { createLazyRoute } from "./routeUtils";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";

export const ThemeContext = React.createContext({
  isDarkTheme: false,
  toggleTheme: () => {}
});

const AsyncApp = createLazyRoute(React.lazy(() => import("./App")));

/**
 * Theme context provider allows the management of theme across the application.
 */
export const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(
    sessionStorage.getItem("isDarkTheme") === "true"
  );

  /**
   * Toggle theme between dark mode and light mode
   */
  const toggleTheme = () => {
    sessionStorage.setItem("isDarkTheme", String(!isDarkTheme));
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <ThemeContext.Provider
      value={{
        isDarkTheme,
        toggleTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

/**
 * Application starting component.
 */
const Index: React.FC = () => {
  const { isDarkTheme } = useContext(ThemeContext);

  const theme = createTheme({
    palette: {
      type: isDarkTheme ? "dark" : "light",
      background: {
        default: isDarkTheme ? "#243746" : "#fff"
      },
      common: {
        purple: "#32255e" /* Solera Jacarta */,
        violet: "#68368b" /* Solera Eminence */,
        darkBlue: "#194fa1" /* Solera Cobalt */,
        darkCyan: "#1a99b0" /* Solera Pelorous */,
        yellow: "#ffb132" /* Solera Sun */,
        darkGreen: "#3a8b6b" /* Solera Viridian */,
        lightGreen: "#a4af66" /* Solera Chelsea */,
        rose: "#7a064e" /* Solera Rose */,
        grey: "#D9D9D9" /* Solera Grey */,
        silver: "#F2F2F2" /* Solera Silver */
      },
      primary: {
        main: "#F2F2F2" /* Main header bar + Filter Pane - Solera Silver*/,
        light: "#F2F2F2" /* Ticker Bar - Solera Silver */,
        dark: "#FFB132" /* Icon Side Bar - Solera Sun */,
        contrastText: "#000000" /* Black */,
        darkBlue: "#4772B4" /*dark blue*/
      },
      secondary: {
        main: "#32255e" /* Side Bar - Solera Jacarta */,
        light: "#68368b" /* Side Bar Icon Hover Over - Solera Eminence*/,
        dark: "#FFB132" /* Filters and Group By - Solera Sun */,
        contrastText: "#000000" /* Black */,
        lightBlue: "#48ADC0" /*light blue*/
      }
    },
    typography: {
      useNextVariants: true
    }
  } as any);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18n}>
        <AsyncApp />
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default Index;

fetch(globalConfigUrl).then((response: any) => {
  response.text().then((result: any) => {
    globalConfig.set(JSON.parse(result));
    const root = createRoot(document.getElementById("root"));
    root.render(
      <ThemeContextProvider>
        <Index />
      </ThemeContextProvider>
    );
  });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
