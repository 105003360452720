import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import de from "./locales/de-DE/translation.json";
import en from "./locales/en-GB/translation.json";
import us from "./locales/en-US/translation.json";
import es from "./locales/es-ES/translation.json";
import fr from "./locales/fr-FR/translation.json";

const i18n = i18next.createInstance();

/**
 * options handling language changes.
 * support languages: English, Spanish, German, French, Korean, Polish, Romanian, Portuguese and Turkish
 */
const options = {
  fallbackLng: ["en", "us", "es", "de", "fr"],
  interpolation: {
    escapeValue: false
  },
  resources: {
    en: {
      translation: en
    },
    us: {
      translation: us
    },
    de: {
      translation: de
    },
    fr: {
      translation: fr
    },
    es: {
      translation: es
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(options);

export default i18n;
