import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import GradientCircularProgress from "./commons/progressComponents/GradientCircularProgress";

export function createLazyRoute<T extends RouteComponentProps>(
  RouteComponent: React.ComponentType<T>
) {
  return function(props: T) {
    return (
      <React.Suspense fallback={<GradientCircularProgress />}>
        <RouteComponent {...props} />
      </React.Suspense>
    );
  };
}
