import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const useStyle = makeStyles((theme: Theme) => {
  return {
    progressSpinner: {
      position: "absolute",
      top: "50%",
      right: "50%",
      zIndex: 3
    }
  };
});

interface Props {
  size?: number;
}

const GradientCircularProgress: React.FC<Props> = props => {
  const { size } = props;
  const classes = useStyle();
  return (
    <div className={classes.progressSpinner}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#68368b" />
            <stop offset="100%" stopColor="#FFB132" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        size={size ? size : 40}
        thickness={5}
      />
    </div>
  );
};

export default GradientCircularProgress;
